import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& :first-child": {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      paddingLeft: 20,
    },
    [theme.breakpoints.down(346)]: {
      "& :nth-child(3)": {
        display: "block",
      },
    },
  },
  link: {
    marginRight: 30,
    cursor: "pointer",
    fontFamily: "'Poppins',sans-serif",
    fontSize: 14,
    color: "rgb(114, 119, 137)",

    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      marginRight: 19,
    },
  },
}));

const StarPageLinks = () => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      <li className={classes.link}>
        <a href="/legals/privacy-notice" target="_blank">
          Privacy Notice
        </a>
      </li>
      <li className={classes.link}>
        <a href="/legals/user-terms-of-use" target="_blank">
          User Terms
        </a>
      </li>
      <li className={classes.link}>
        <a href="/legals/organisation-terms-of-use" target="_blank">
          Organisation Terms
        </a>
      </li>
      <li className={classes.link}>
        <a href="/legals/cookie-notice" target="_blank">
          Cookie Notice
        </a>
      </li>
    </ul>
  );
};

export default StarPageLinks;
