import { UrlObject } from "url";

import { makeStyles, Typography } from "@material-ui/core";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { CSSProperties } from "react";

type RoleButtonProps = {
  iconPath: string;
  title: string;
  link: UrlObject | string;
  subtitle?: string;
  variant?: "small";
  style?: CSSProperties;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgb(246, 248, 249)",
    borderRadius: 15,
    height: 120,
    width: 440,
    padding: "21px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0",
    [theme.breakpoints.down("md")]: {
      maxWidth: 420,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 288,
      height: 130,
      padding: "0 35px 0 25px",
    },

    "&:hover": {
      border: "2px solid rgb(101, 186, 71)",
      background: "#FFFFFF",
      padding: "21px 33px",
      cursor: "pointer",
    },
  },
  variant_small: {
    width: 170,
    height: 150,
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "21px 33px",
    [theme.breakpoints.down("md")]: {
      maxWidth: 150,
      maxHeight: 132,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 113,
      maxHeight: 100,
      padding: "13px 40px",
    },

    "&:hover": {
      padding: "19px 33px",
    },
  },
  titleContainer: {
    width: 286,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 25,
    },
  },
  title: {
    color: "rgb(20, 30, 73)",
    fontSize: 22,
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
  subtitle: {
    color: "rgb(114, 119, 137)",
    fontSize: 16,
    fontWeight: "normal",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
}));

const RoleButton = ({ iconPath, title, subtitle, link, variant, style }: RoleButtonProps) => {
  const classes = useStyles();
  const router = useRouter();

  const navigate = () => {
    router.push(link);
  };

  return (
    <div
      style={style}
      className={`${classes.root} ${variant === "small" ? classes.variant_small : ""}`}
      onClick={navigate}
    >
      <Image src={iconPath} width={50} height={50} alt="" />
      {variant === "small" ? (
        <Typography className={classes.title}>{title}</Typography>
      ) : (
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </div>
      )}
    </div>
  );
};

export default RoleButton;
