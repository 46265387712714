import { Button } from "@material-ui/core";
import Image from "next/image";
import React from "react";

import StarPageLinks from "@/components/legals/LegalLinks/StarPageLinks";

import { Organization } from "../../types/Organization";
import RoleButton from "../shared/RoleButton";
import Typography from "../shared/Typography";

import styles from "./styles";

interface HomeProps {
  redirectToSignIn: () => void;
  organisation?: Organization;
}

export default function Home({ redirectToSignIn, organisation }: HomeProps) {
  const classes = styles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <img src={organisation?.logoUrl || "/logo_dark.svg"} alt="logo_dark" height={36} />
        <div className={classes.navSignIn}>
          <Typography size="large" color="textSecondary" className={classes.questionText}>
            Already have an account?
          </Typography>
          <Button variant="contained" onClick={redirectToSignIn}>
            Sign In
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.roleWrapper}>
          <Typography variant="h1" color="textPrimary" className={classes.warningText}>
            {organisation
              ? `Securely access employment or income records for past and present ${organisation.name} workers.`
              : "Securely access employment or income records for any UK-based worker, with their consent."}
          </Typography>
          <Typography variant="h4" color="textPrimary" className={classes.selectRoleTitle}>
            Are you a Verifier? Sign up!
          </Typography>
          <div className={classes.roleButtonsWrapper}>
            <RoleButton iconPath="/ic_verifier.png" title="Verifier" link="/sign-up" variant="small" />
          </div>
        </div>
        <div className={classes.mainImage}>
          <Image src="/home.png" alt="home" width={663} height={501} />
        </div>
      </div>
      <div className={classes.backgroundLogo}>
        <Image src="/k_logo.png" alt="k_logo" width={281} height={394} />
      </div>
      <div className={classes.footer}>
        <StarPageLinks />
      </div>
    </div>
  );
}
