import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    maxWidth: 1440,
    margin: "auto",
    position: "relative",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      maxWidth: 1024,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      minWidth: 320,
    },
  },
  header: {
    padding: "42px 50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "35px 40px 0 ",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 40px 0 ",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px 16px 0 ",
    },
  },
  content: {
    flex: "1 0 auto",
    paddingLeft: 50,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      paddingTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
  mainImage: {
    width: 663,
    height: 501,
    paddingTop: 25,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 393,
      display: "none",
    },
  },
  navSignIn: {
    display: "flex",
    alignItems: "center",
  },
  selectRoleTitle: {
    margin: "40px 0 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "68px 0 20px",
      fontSize: 22,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "30px 0 0",
      fontSize: 16,
    },
  },
  roleButtonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 360,
  },
  backgroundLogo: {
    position: "absolute",
    top: 593,
    left: 497,
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 200,
      top: 450,
      left: 410,
    },
    [theme.breakpoints.down("xs")]: {
      top: 235,
      left: 191,
    },
  },
  questionText: {
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  roleWrapper: {
    paddingTop: 54,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 58,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 48,
    },
  },
  warningText: {
    maxWidth: 809,
    [theme.breakpoints.down("sm")]: {
      width: 487,
      fontSize: 36,
      height: 184,
      lineHeight: "46px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 288,
      fontSize: 26,
      lineHeight: "34px",
      height: 204,
      fontWeight: 500,
    },
  },
  footer: {
    flexShrink: 0,
  },
}));
