import { useRouter } from "next/router";
import React, { useEffect } from "react";

import Home from "../components/Home";
import useCurrentUser from "../hooks/useCurrentUser";

const HomePage = () => {
  const router = useRouter();
  const user = useCurrentUser();

  useEffect(() => {
    if (user.isLoggedIn) {
      router.replace("/requests");
    }
  });

  const redirectToSignIn = () => {
    router.push("/sign-in");
  };

  return <Home redirectToSignIn={redirectToSignIn} />;
};

export default HomePage;
